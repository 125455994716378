.main{
    margin-top: 60px;
}

.project-image{
    max-width: 1000px;
}

.pic-collage{
    display: flex;
    flex-direction: row;
}

.project-image-sm{
    max-width: 300px;
}

.project-description{
    max-width: 1000px;
    padding: 40px;
    text-align: left;
}

.project-image-card{
    max-width: 300px;
    text-align: left;
    padding: 20px;
}

hr{
    margin-top: 40px;
}

.loader  {
    position: absolute;  
    top: 50%;
    left: 50%;
    margin-left: -175px;
    margin-top: -200px;
  }

  .project-image-description{
    font-weight: normal;
    margin: 20px 0px 60px 0px;
  }

@media screen and (max-width: 430px) {
  .main{
    flex: 0 0 auto;
    flex-direction: column;
    margin-top: 50px;
  }

  .project-image{
    max-width: 80%;
  }

  .navbar{
    max-width: 100%;
  }

  .footer{
    bottom: 0;
  }

  .pic-collage{
    flex-direction: column;
  }
}

@media screen and (max-width: 1024px) {
  .main{
    flex: 0 0 auto;
    margin-top: 50px;
  }

  .project-image{
    max-width: 80%;
  }

  .navbar{
    max-width: 100%;
  }

  .pic-collage{
    flex-wrap: wrap;
  }

  .footer{
    position: fixed;
  }
}