.section-card-image-container{
    min-height: 125px;
    width: 200px;
}

.section-card-image{
    height: 150px;
    width: 200px;
    border-radius: 10%;
    object-fit: cover;
    overflow: hidden;
}

.section-card{
    max-width: 200px;
    margin: 20px;
}

.section-card-details{
    font-size: .8rem;
    text-align:left;
}
