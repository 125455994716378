.resume{
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.download-button{
  margin-top: 60px;
}

button{
  background: #C0C0C0;
  color: #696969;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: calc(10px + .6vh);
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 4px;
}

@media screen and (max-width: 430px) {
  .resume{
    flex: 0 0 auto;
    flex-direction: column;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .resume{
    flex: 0 0 auto;
    margin-top: 20px;
  }
}