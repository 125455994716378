.App{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  background-color: #282c34;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

main{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}