.footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 100%;
    height: 35px;
    background-color: #696969;
    position: fixed;
    bottom: 0;
}

.footer-image{
    height: 25px;
    width: 25px;
    margin: 5px 20px 5px 20px;
    opacity: .8;
}

@media screen and (max-width: 430px) {
    .footer-image{
        margin-bottom: 5px;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .footer-image{
        margin-bottom: 5px;
        padding: 0px;
    }
  }