.navbar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    z-index: 10;
    min-width: 100%;
    height: 50px;
    background-color: #696969;
}

.navbar-link {
    margin-left: 10px;
    margin-right: 10px;
    line-height: 45px;
    color: #C0C0C0;
    background-color: #696969;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: calc(10px + 1vh);
}


@media screen and (max-width: 420px) {
    .navbar-link{
      font-size: calc(10px + .2vh);
      line-height: 50px;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .navbar-link{
        font-size: calc(10px + .6vh);
        line-height: 50px;
    }
  }