.main, .project-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #C0C0C0;
  text-decoration: none;
}

.headline {
  font-size: 6rem;
  font-weight: bolder;
  margin: 60px 30px 30px 30px;
}

.sub-header {
  font-size: 1.5rem;
  font-weight: bolder;
  margin: 30px;
}

.mission {
  max-width: 40%;
  margin: 30px 0px 30px 0px;
  text-align: justify;
  line-height: 150%;
}

.avatar {
  max-width: 20%;
  max-height: 20%;
}

.logo {
  max-width: 10%;
  max-height: 10%;
}

h2{
  text-align: center;
}

.section{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.copyright{
  margin: 20px 0px 60px 0px;
}

@media screen and (max-width: 420px) {
  .main, .project-link{
    flex: 0 0 auto;
    flex-direction: column;
  }

  .section{
    display: flex;
    max-width: 400px;
    flex-wrap: wrap;
    align-items: center;
  }

  .project-link{
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
  }

  h2{
    font-size: 1.5rem;
  }

  .headline{
    font-size: 4rem;
  }

  .sub-header{
    font-size: 1.3rem;
  }

  .mission {
    max-width: 70%;
  }

  .avatar {
    max-width: 40%;
    max-height: 40%;
  }
}

@media screen and (max-width: 1024px) {
  .main, .project-link{
    flex: 0 0 auto;
  }

  .section{
    flex: 0 0 auto;
    max-width: 600px;
    flex-wrap: wrap;
    margin: 10px auto 10px auto;
  }

  .mission {
    max-width: 70%;
  }

  .project-link{
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
  }

  .avatar {
    max-width: 50%;
    max-height: 50%;
  }
}